import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://101e8c83af32660eaac8ecd798933d4a@o4506599007911936.ingest.sentry.io/4506634411245568",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});